<template>
 <div class="work-order-gather">
   <van-nav-bar
       left-arrow
       left-text="返回"
       @click-left="goBack"
       :title="moduleTitle"
   />
   <van-radio-group v-model="selDateType" direction="horizontal" class="date-radio">
     <van-radio name="1">七天</van-radio>
     <van-radio name="2">近一月</van-radio>
     <van-radio name="3">自定义</van-radio>
     <van-button type="primary" v-show="selDateType !== '3'" @click="onConfirm">查询</van-button>
   </van-radio-group>
   <van-cell-group class="date-cell-group" v-show="selDateType === '3'">
     <van-cell title="选择日期区间" :value="selDate" @click="show = true" is-link/>
     <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :allow-same-day="true"/>
   </van-cell-group>

   <template v-for="drawDiv in drawDivs">
     <div :id="'drawDivI' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivIScore' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivIScoreRate' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivR' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivRScore' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
     <div :id="'drawDivRScoreRate' + drawDiv" style="width: 100%;height:400px;" class="draw-div"></div>
   </template>
 </div>
</template>

<script>

export default {
  name: "Analysis1003",
  data() {
    return {
      moduleTitle: '',
      selDateType: '1',
      minDate: new Date(2020,1,1),
      show: false,
      miniReqParams: {
        //0=今天，1=时间区间
        timeConditionType: '0',
        startDate: '',
        endDate: '',
        month: ''
      },
      drawDivs: [],
      existCharts: []
    }
  },
  methods:{
    goBack() {
      this.$router.back();
    },
    onConfirm(date) {
      if(this.selDateType === '3') {
        const [start, end] = date;
        this.show = false;
        this.miniReqParams.timeConditionType = '1'
        this.miniReqParams.startDate = this.dateUtils.formatDate(start);
        this.miniReqParams.endDate = this.dateUtils.formatDate(end);
      } else {
        if(this.selDateType === '1') {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(7);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        } else {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(30);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        }
      }
      this.$toast.loading({message: '请求中', overlay: true});
      this.sendQuery();
    },
    sendQuery() {
      //发送查询请求
      this.postRequest('/wechat-analysis/evaluate-order-gather', this.miniReqParams).then(resp => {
        if(resp) {
          if(this.existCharts.length > 0) {
            this.existCharts.forEach(charts => {
              charts.dispose();
            })
            this.existCharts = [];
          }
          let data = resp.data;
          if(data && data.length > 0) {
            this.drawDivs = Object.keys(data);
            this.$nextTick(() => {
              this.drawCharts(data);
            })
          } else {
            this.$toast("查询无数据，请换条件查询！")
          }
        }
      })
    },
    drawCharts(data) {
      let titleGroup = ['安装工单趋势图','安装评价数','安装评价率','维修工单趋势图','维修评价数','维修评价率']
      let legendGroup = [['完工安装工单数','安装完工设备数','安装完工评价工单数'],['安装好评','安装中评','安装差评'],['安装评价率','安装好评率','安装差评率']
        ,['完工维修工单数','维修完工设备数','维修评价工单数'],['维修好评','维修中评','维修差评'],['维修评价率','维修好评率','维修差评率']];
      let columnsGroup = [['azcut','azmachine_num','azraisecut'],['azcut1','azcut2','azcut3'],['azrate','azrate1','azrate3'],
        ['wxcut','wxmachine_num','wxraisecut'],['wxcut1','wxcut2','wxcut3'],['wxrate','wxrate1','wxrate3']];
      //数据组装
      data.forEach((company, index) => {
        let key = Object.keys(company);
        let items = company[key];
        legendGroup.forEach((legend, i) => {
          let params = {};
          //标题
          params.title = key + ' ' + titleGroup[i];
          //线条名称
          params.legend = legend;
          params.columns = columnsGroup[i];
          //日期
          params.showDates = [];
          //展示的内容
          params.series = [];
          params.columns.forEach((column, columnIndex) => {
            let columnData = [];
            items.forEach(item => {
              columnData.push(item[column]);
              if(columnIndex == 0) {
                params.showDates.push(item.udate)
              }
            })
            let obj =  {
              name: params.legend[columnIndex],
              type: 'line',
              stack: column,
              data: columnData
            }
            params.series.push(obj);
          })
          if(i == 0) {
            this.myEcharts('drawDivI' + index, params);
          } else if(i == 1) {
            this.myEcharts('drawDivIScore' + index, params);
          } else if(i == 2) {
            this.myEcharts('drawDivIScoreRate' + index, params);
          } else if(i == 3) {
            this.myEcharts('drawDivR' + index, params);
          } else if(i == 4) {
            this.myEcharts('drawDivRScore' + index, params);
          } else {
            this.myEcharts('drawDivRScoreRate' + index, params);
          }
        });
      })
    },
    myEcharts(id, params){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      let option = {
        title: {
          text: params.title
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
        },
        legend: {
          show: true,
          top: '6%',
          data: params.legend
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '11%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: params.showDates
        },
        yAxis: [{
          type: 'value',
          axisTick: {
            inside: true
          },
          scale: true,
          axisLabel: {
            margin: 2,
            formatter: function (value, index) {　　
              //y轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + "万";
              }
              return value;
            }
          },
          "splitLine": {//网格线 默认true
            "show": true
          }
        }],
        series: params.series
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    },
  },
  computed: {
    selDate() {
      if(this.miniReqParams.startDate.length > 0
          && this.miniReqParams.endDate.length > 0) {
        return `${this.miniReqParams.startDate}-${this.miniReqParams.endDate}`
      }
    }
  },
  mounted: function () {
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  }
}
</script>

<style scoped>
.date-radio {
  margin-top: 15px;
  background-color: #fff;
  padding: 10px 16px;
}
.date-radio>button {
  margin-left: auto;
}
.draw-div {
  margin-top: 15px;
  background-color: white;
}
.van-nav-bar__content {
  margin-bottom: 10px;
}
.work-order-gather {
  padding-bottom: 15px;
  width: 100%;
}
.date-cell-group {
  margin: 15px 0;
}
.van-cell__value {
  color: #999;
}
</style>